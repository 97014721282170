import { Box, keyframes } from "@chakra-ui/react"
import { SVGProps } from "react"

const shine = keyframes`
  0% { transform: translateX(-100%) skewX(-45deg); }
  100% { transform: translateX(100%) skewX(-45deg); }
`

const sparkle = keyframes`
  0% { opacity: 0; transform: translate(-50%, -50%) scale(0) rotate(0deg); }
  50% { opacity: 1;}
  100% { opacity: 0; transform: translate(-105%, -105%) scale(0.6) rotate(-20deg); }
`

const sparkleAlt = keyframes`
  0% { opacity: 0; transform: translate(-50%, -50%) scale(0) rotate(0deg); }
  50% { opacity: 1;}
  100% { opacity: 0; transform: translate(5%, 5%) scale(0.6) rotate(20deg); }
`

const sparkleThird = keyframes`
  0% { opacity: 0; transform: translate(-50%, -50%) scale(0) rotate(0deg); }
  50% { opacity: 1;}
  100% { opacity: 0; transform: translate(5%, -105%) scale(0.6) rotate(20deg); }
`

const StarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 32" fill="currentColor" {...props}>
    <path d="M11.99,32c-.45-.93-.59-3.12-.93-4.42-1.07-5.32-3.34-8.59-7.21-10.16-1.06-.56-3.08-.8-3.85-1.48.22-.39,1.57-.64,2.2-.89,5.82-1.51,8.44-6.32,9.45-13.91.28-1.52.41-1.52.69,0,1.01,7.59,3.64,12.4,9.46,13.91.63.25,2,.5,2.2.88-.79.69-2.78.92-3.85,1.48-3.87,1.57-6.15,4.84-7.21,10.16-.34,1.3-.48,3.51-.93,4.42h-.02Z" />
  </svg>
)

interface AITagProps {
  size?: string
  color?: string
}

export const AITag = ({ size = "14px", color = "brand.500" }: AITagProps) => {
  return (
    <Box display="inline-block" color={color} pos="relative" w={size} h={size}>
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        bgGradient="linear(to-r, transparent, whiteAlpha.500, transparent)"
        animation={`${shine} 1.5s linear infinite 0.5s`}
        zIndex={1}
      />
      <Box position="relative">
        <StarIcon width={size} height={size} />
        <Box
          position="absolute"
          top="50%"
          left="50%"
          style={{ opacity: 0 }}
          animation={`${sparkle} 2s linear infinite`}
        >
          <StarIcon width={size} height={size} />
        </Box>
        <Box
          position="absolute"
          top="50%"
          left="50%"
          width={size}
          height={size}
          style={{ opacity: 0 }}
          animation={`${sparkleAlt} 2s linear infinite 0.8s`}
        >
          <StarIcon width={size} height={size} />
        </Box>
        <Box
          position="absolute"
          top="50%"
          left="50%"
          width={size}
          height={size}
          style={{ opacity: 0 }}
          animation={`${sparkleThird} 2s linear infinite 1.3s`}
        >
          <StarIcon width={size} height={size} />
        </Box>
      </Box>
    </Box>
  )
}
