import { z } from "zod"
import {
  INDUSTRY_OPTIONS,
  PEOPLE_HIGHLIGHT_OPTIONS,
  SIGNAL_REGION_OPTIONS,
} from "~/consts/signals"
import {
  TALENT_SIGNAL_DEPARTMENT,
  TALENT_SIGNAL_SENIORITY,
  TALENT_SIGNAL_STATUS,
  TALENT_SIGNAL_TYPE,
  TALENT_SIGNAL_YEARS_OF_EXPERIENCE,
} from "~/consts/talentSignals"
import {
  numericFilterSchema,
  NUMERIC_OPERATORS,
  setFilterSchema,
  textFilterSchema,
} from "~/utils/signal"
import { signalDateFilterSchema } from "~/utils/signalDate"

export const talentSignalFiltersSchema = z
  .object({
    SignalDate: signalDateFilterSchema,
    SignalStatus: z.array(z.nativeEnum(TALENT_SIGNAL_STATUS)),
    SignalType: z.array(z.nativeEnum(TALENT_SIGNAL_TYPE)),
    SignalScore: numericFilterSchema(),
    OutOfStealthAdvantage: numericFilterSchema(),
    TalentLatestSignal: z.enum(["Yes", "No"]),
    Location: z.array(z.string()),
  })
  .partial()

export const talentCompanyFiltersSchema = z
  .object({
    PastPosition: textFilterSchema,
    NewPosition: textFilterSchema,
    PastCompany: z.array(z.string()),
    NewCompany: z.array(z.string()),
    PastCompanyWebsite: textFilterSchema,
    NewCompanyWebsite: textFilterSchema,
    NewCompanyTagline: textFilterSchema,
    FoundedDate: numericFilterSchema({
      defaultValue: NUMERIC_OPERATORS.GTE,
      extraConstraints: (z) => z.lte(2999),
    }),
    Industry: setFilterSchema(z.enum(INDUSTRY_OPTIONS)),
    LinkedInURL: textFilterSchema,
    Tags: textFilterSchema,
    AnnouncementDelayMonths: numericFilterSchema(),
  })
  .partial()

export const talentTalentFiltersSchema = z
  .object({
    Name: textFilterSchema,
    Languages: textFilterSchema,
    LinkedInURLTalent: textFilterSchema,
    TwitterURLTalent: textFilterSchema,
    TwitterFollowers: numericFilterSchema(),
    TwitterFFRatio: numericFilterSchema(),
    Highlights: setFilterSchema(z.enum(PEOPLE_HIGHLIGHT_OPTIONS)),
    Person: z.array(z.string()),
  })
  .partial()

export const talentExperienceFiltersSchema = z
  .object({
    SerialFounder: z.enum(["Yes", "No"]),
    seniority: z.array(z.nativeEnum(TALENT_SIGNAL_SENIORITY)),
    YearsOfExperience: z.array(z.nativeEnum(TALENT_SIGNAL_YEARS_OF_EXPERIENCE)),
    Department: z.array(z.nativeEnum(TALENT_SIGNAL_DEPARTMENT)),
  })
  .partial()

export const talentEducationFiltersSchema = z
  .object({
    Education: textFilterSchema,
    EducationDate: textFilterSchema,
    PastEducation: textFilterSchema,
    PastEducationDate: textFilterSchema,
  })
  .partial()

const DEPRECATED_FIELDS = z
  .object({
    // * Add deprecated fields here:
    // * These filters will remain valid, but won't show in the filters UI
    Region: z.array(z.enum(SIGNAL_REGION_OPTIONS)),
  })
  .partial()

export const talentSignalFiltersValidation = talentSignalFiltersSchema
  .merge(talentCompanyFiltersSchema)
  .merge(talentTalentFiltersSchema)
  .merge(talentExperienceFiltersSchema)
  .merge(talentEducationFiltersSchema)
  .merge(DEPRECATED_FIELDS)

export type TalentSignalFilters = z.infer<typeof talentSignalFiltersValidation>

export const talentAdvancedFiltersSchema = z.object({
  Signal: z.object({
    Signal: talentSignalFiltersSchema,
  }),
  Talent: z.object({
    Talent: talentTalentFiltersSchema,
  }),
  Experience: z.object({
    Experience: talentExperienceFiltersSchema,
  }),
  Company: z.object({
    Company: talentCompanyFiltersSchema,
  }),
  Education: z.object({
    Education: talentEducationFiltersSchema,
  }),
})

export const talentSignalQuickFiltersSchema = z
  .object({
    SignalDate: signalDateFilterSchema,
    SignalType: z.array(z.nativeEnum(TALENT_SIGNAL_TYPE)),
    SignalStatus: z.array(z.nativeEnum(TALENT_SIGNAL_STATUS)),
    Location: z.array(z.string()),
    Industry: setFilterSchema(z.enum(INDUSTRY_OPTIONS)),
    PastCompany: z.array(z.string()),
    NewCompany: z.array(z.string()),
  })
  .partial()

export type TalentSignalQuickFilters = z.infer<
  typeof talentSignalQuickFiltersSchema
>
