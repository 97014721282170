import { z } from "zod"
import {
  AVERAGE_TENURE_OPTIONS,
  EDUCATION_LEVELS,
  PEOPLE_HIGHLIGHT_OPTIONS,
  SENIORITY_LEVELS,
} from "~/consts/signals"
import {
  companyOperatorsFilterSchema,
  currentTenureFilterSchema,
  getInnerSchemaAndOptions,
  languagesFilterSchema,
  numericFilterRevampedSchema,
  setFilterSchema,
} from "~/utils/signal"

export type PeopleDBFilters = z.infer<typeof peopleFiltersValidation>

const peopleOverviewFiltersSchema = z
  .object({
    Location: z.array(z.string()),
    Highlights: setFilterSchema(z.enum(PEOPLE_HIGHLIGHT_OPTIONS)),
    Skills: setFilterSchema(),
    Languages: languagesFilterSchema,
  })
  .partial()

const peopleExperienceFiltersSchema = z
  .object({
    JobTitles: companyOperatorsFilterSchema(
      getInnerSchemaAndOptions("JobTitles")
    ),
    PastJobTitles: companyOperatorsFilterSchema(
      getInnerSchemaAndOptions("PastJobTitles")
    ),
    Department: companyOperatorsFilterSchema(
      getInnerSchemaAndOptions("Department")
    ),
    YearsOfExperience: numericFilterRevampedSchema(),
    SeniorityLevel: z.array(z.enum(SENIORITY_LEVELS)),
    CurrentTenure: currentTenureFilterSchema,
    AverageTenure: z.enum(AVERAGE_TENURE_OPTIONS),
  })
  .partial()

const peopleCompanyOperatorFiltersSchema = z
  .object({
    Companies: companyOperatorsFilterSchema(
      getInnerSchemaAndOptions("Companies")
    ),
    HasCurrentPosition: z.boolean(),
    CompanyHQLocation: companyOperatorsFilterSchema(
      getInnerSchemaAndOptions("CompanyHQLocation")
    ),
    CompanyIndustries: companyOperatorsFilterSchema(
      getInnerSchemaAndOptions("CompanyIndustries")
    ),
    CompanyGrowthStage: companyOperatorsFilterSchema(
      getInnerSchemaAndOptions("CompanyGrowthStage")
    ),
    CompanySize: companyOperatorsFilterSchema(
      getInnerSchemaAndOptions("CompanySize")
    ),
    CompanyTotalFundingAmount: companyOperatorsFilterSchema(
      getInnerSchemaAndOptions("CompanyTotalFundingAmount")
    ),
    CompanyFundingType: companyOperatorsFilterSchema(
      getInnerSchemaAndOptions("CompanyFundingType")
    ),
    CompanyLastFundingAmount: companyOperatorsFilterSchema(
      getInnerSchemaAndOptions("CompanyLastFundingAmount")
    ),
    CompanyInvestors: companyOperatorsFilterSchema(
      getInnerSchemaAndOptions("CompanyInvestors")
    ),
  })
  .partial()

const peopleEducationFiltersSchema = z
  .object({
    Education: setFilterSchema(),
    EducationLocation: z.array(z.string()),
    EducationLevel: z.array(z.enum(EDUCATION_LEVELS)),
    FieldOfStudy: setFilterSchema(),
    GraduationYear: numericFilterRevampedSchema(),
  })
  .partial()

const peopleSocialFiltersSchema = z
  .object({
    Connections: numericFilterRevampedSchema(),
    Followers: numericFilterRevampedSchema(),
  })
  .partial()

export const peopleFiltersValidation = peopleOverviewFiltersSchema
  .merge(peopleExperienceFiltersSchema)
  .merge(peopleCompanyOperatorFiltersSchema)
  .merge(peopleEducationFiltersSchema)
  .merge(peopleSocialFiltersSchema)

export const peopleAdvancedFiltersSchema = z.object({
  General: z.object({ General: peopleOverviewFiltersSchema }),
  Experience: z.object({
    Experience: peopleExperienceFiltersSchema,
  }),
  Companies: z.object({
    Companies: peopleCompanyOperatorFiltersSchema,
  }),
  Education: z.object({ Education: peopleEducationFiltersSchema }),
  Social: z.object({ Social: peopleSocialFiltersSchema }),
})
