import { CustomerFocus } from "@prisma/client"
import { z } from "zod"
import { findQueryFromQueryId, findQueryFromSearchId } from "~/utils/db/queries"
import { Pagination } from "~/utils/db/queries/types"
import { companySignalSortValidation } from "~/utils/db/sorting"
import { normaliseToArray } from "~/utils/db/sql/utils"

import {
  ANNUAL_REVENUE_RANGES,
  COMPANY_HIGHLIGHTS_OPTIONS,
  COMPANY_SIZE_OPTIONS,
  FUNDING_TYPE_OPTIONS,
  GROWTH_STAGE_OPTIONS,
  INDUSTRY_OPTIONS,
  PEOPLE_HIGHLIGHT_OPTIONS,
  SIGNAL_REGION_OPTIONS,
  SUB_INDUSTRY_OPTIONS,
} from "~/consts/signals"
import {
  booleanFilterSchema,
  dateFilterSchema,
  descriptionFilterSchema,
  growthFilterSchema,
  numericFilterRevampedSchema,
  NUMERIC_OPERATORS,
  NUMERIC_OPERATORS_REVAMPED,
  OperatingStatusEnum,
  setFilterSchema,
  textFilterSchema,
} from "~/utils/signal"
import { signalDateFilterSchema } from "~/utils/signalDate"
import { hotSignalsSchema } from "./stratintel"

const companyCompanyFiltersSchema = z
  .object({
    HQLocation: z.array(z.string()),
    growthStage: z.array(z.enum(GROWTH_STAGE_OPTIONS)),
    Highlights: setFilterSchema(z.enum(COMPANY_HIGHLIGHTS_OPTIONS)),
    Rank: numericFilterRevampedSchema({
      defaultValue: NUMERIC_OPERATORS_REVAMPED.LTE,
    }),
    FoundedDate: numericFilterRevampedSchema({
      defaultValue: NUMERIC_OPERATORS.GTE,
      extraConstraints: (z) => z.lte(new Date().getFullYear()),
    }),
    OperatingStatus: z.array(z.nativeEnum(OperatingStatusEnum)),
    traction: textFilterSchema,
    clients_mapped: setFilterSchema(),
  })
  .partial()

const companyFoundersFiltersSchema = z
  .object({
    Founders: setFilterSchema(),
    FounderHighlights: setFilterSchema(z.enum(PEOPLE_HIGHLIGHT_OPTIONS)),
  })
  .partial()

const companyExtraFiltersSchema = z.object({
  id: z.string().optional(), // This filter is LIKE lookup
  ids: z.string().array().optional(), // This is an equality for any
  Domain: z.string().optional(),
  website: z.string().optional(),
  "LinkedIn - URL": z.string().optional(),
  linkedinId: z.number().optional(),
  crunchbaseUrl: z.string().optional(),
})

const companyEmployeesFiltersSchema = z
  .object({
    EmployeeCount: numericFilterRevampedSchema(),
    "composed_growth.employee_count": growthFilterSchema(),
  })
  .partial()

const companyCompanySizeFiltersSchema = z
  .object({
    CompanySize: z.array(z.nativeEnum(COMPANY_SIZE_OPTIONS)),
  })
  .partial()

const companyTalentMovementFiltersSchema = z
  .object({
    NewHires: dateFilterSchema,
    RecentLeavers: dateFilterSchema,
    RecentPromotions: dateFilterSchema,
  })
  .partial()

const companyNameSearch = z
  .object({
    nameSearch: z.string().nullable(),
  })
  .partial()

const companyFundingFiltersSchema = z
  .object({
    TotalFundingAmount: numericFilterRevampedSchema({
      defaultValue: NUMERIC_OPERATORS.LTE,
    }),
    LastFundingType: z.array(z.enum(FUNDING_TYPE_OPTIONS)),
    LastFundingAmount: numericFilterRevampedSchema({
      defaultValue: NUMERIC_OPERATORS.LTE,
    }),
    LastFundingDate: dateFilterSchema,
    NumberOfFundingRounds: numericFilterRevampedSchema(),
    PostMoneyValuation: numericFilterRevampedSchema(),
  })
  .partial()

const companyInvestorsFiltersSchema = z
  .object({
    Investors: setFilterSchema(),
    NumberOfInvestors: numericFilterRevampedSchema(),
  })
  .partial()

const companyValuationFiltersSchema = z
  .object({
    AnnualRevenueEstimate: z.array(z.nativeEnum(ANNUAL_REVENUE_RANGES)),
    HotCompanies: hotSignalsSchema,
  })
  .partial()

const companySectorsFiltersSchema = z
  .object({
    b2x: z.array(z.nativeEnum(CustomerFocus)),
    tcp: textFilterSchema,
    Industry: setFilterSchema(z.enum(INDUSTRY_OPTIONS)),
    SubIndustry: setFilterSchema(z.enum(SUB_INDUSTRY_OPTIONS)),
    Description: descriptionFilterSchema,
    semanticSearch: z.string().optional(),
  })
  .partial()

const companyWebVisitsFiltersSchema = z
  .object({
    WebVisits: numericFilterRevampedSchema(),
    "composed_growth.web_visits": growthFilterSchema(),
  })
  .partial()

const companyWebsitePopularityRankFiltersSchema = z
  .object({
    WebsitePopularityRank: numericFilterRevampedSchema({
      defaultValue: NUMERIC_OPERATORS_REVAMPED.LTE,
    }),
    "composed_growth.popularity_rank.diff": growthFilterSchema(),
  })
  .partial()

const companyLinkedInFiltersSchema = z
  .object({
    LinkedInFollowers: numericFilterRevampedSchema(),
    "composed_growth.linkedin_followers": growthFilterSchema(),
  })
  .partial()

const companyTwitterFiltersSchema = z
  .object({
    TwitterFollowers: numericFilterRevampedSchema(),
    "composed_growth.twitter_followers": growthFilterSchema(),
  })
  .partial()

const companyInstagramFiltersSchema = z
  .object({
    InstagramFollowers: numericFilterRevampedSchema(),
    "composed_growth.instagram_followers": growthFilterSchema(),
  })
  .partial()

const companyAppDownloadsFiltersSchema = z
  .object({
    TotalAppDownloads: numericFilterRevampedSchema(),
    "composed_growth.total_app_downloads": growthFilterSchema([
      "1mo",
      "3mo",
      "6mo",
    ]),
  })
  .partial()

const companyiTunesFiltersSchema = z
  .object({
    iTunesReviews: numericFilterRevampedSchema(),
    "composed_growth.itunes_reviews": growthFilterSchema(),
  })
  .partial()

const companyGoogleFiltersSchema = z
  .object({
    GooglePlayReviews: numericFilterRevampedSchema(),
    "composed_growth.googleplay_reviews": growthFilterSchema(),
  })
  .partial()

const companyActiveTechnologiesFiltersSchema = z
  .object({
    ActiveTechnologies: setFilterSchema(),
  })
  .partial()

const companyAwardsFiltersSchema = z
  .object({
    Awards: setFilterSchema(),
    AwardsCount: numericFilterRevampedSchema(),
    certifications_mapped: setFilterSchema(),
  })
  .partial()

const companyG2FiltersSchema = z
  .object({
    G2ProductReviews: booleanFilterSchema,
    G2Medals: booleanFilterSchema,
    G2TotalReviews: numericFilterRevampedSchema(),
    G2Rating: numericFilterRevampedSchema(),
    // TODO: M and D don't want this for now, add when we have history on products
    // G2Products: textFilterSchema,
  })
  .partial()

const companyTrustpilotFiltersSchema = z
  .object({
    TrustpilotReviews: booleanFilterSchema,
    TrustpilotTotalReviews: numericFilterRevampedSchema(),
    TrustpilotRating: numericFilterRevampedSchema(),
  })
  .partial()

const subSection = companyAppDownloadsFiltersSchema.merge(
  companyActiveTechnologiesFiltersSchema
)

const companyNonFilterableFieldsSchema = z
  .object({
    new: z.boolean().optional(),
    NewHighlights: setFilterSchema(z.enum(COMPANY_HIGHLIGHTS_OPTIONS)),
    HasNewHighlights: z.boolean().optional(),
    HasNewFundingHighlights: z.boolean().optional(),
    HasNewGrowthHighlights: z.boolean().optional(),
  })
  .partial()

const companySocialFiltersSchema = z.object({
  ...companyLinkedInFiltersSchema.shape,
  ...companyTwitterFiltersSchema.shape,
  ...companyInstagramFiltersSchema.shape,
  ...companyiTunesFiltersSchema.shape,
  ...companyGoogleFiltersSchema.shape,
  ...subSection.shape,
})

export const companyCRMFiltersSchema = z
  .object({
    LastActivityDate: signalDateFilterSchema.optional(),
    inCRM: z.boolean().optional(),
  })
  .partial()

const DEPRECATED_FIELDS = z
  .object({
    // * Add deprecated fields here:
    Tags: z.literal("DEPRECATED"),
    HQRegion: z.array(z.enum(SIGNAL_REGION_OPTIONS)),
  })
  .partial()

const companySignalListFilterSchema = z
  .object({
    listId: z.string().optional(),
    landscapeId: z.string().optional(),
    landscapeColumnId: z.string().optional(),
  })
  .partial()

type DateOperator = "after" | "equals" | "before"

export const companySignalFiltersValidation = z.object({
  ...companySignalListFilterSchema.shape,
  ...companyCompanyFiltersSchema.shape,
  ...companySectorsFiltersSchema.shape,
  ...companyFundingFiltersSchema.shape,
  ...companyInvestorsFiltersSchema.shape,
  ...companyValuationFiltersSchema.shape,
  ...companyFoundersFiltersSchema.shape,
  ...companyEmployeesFiltersSchema.shape,
  ...companyWebVisitsFiltersSchema.shape,
  ...companyWebsitePopularityRankFiltersSchema.shape,
  ...companyNameSearch.shape,
  ...companyNonFilterableFieldsSchema.shape,
  ...companyAwardsFiltersSchema.shape,
  ...companyG2FiltersSchema.shape,
  ...companyTrustpilotFiltersSchema.shape,
  ...companySocialFiltersSchema.shape,
  ...companyTalentMovementFiltersSchema.shape,
  ...companyCRMFiltersSchema.shape,
  ...DEPRECATED_FIELDS.shape,
  ...companyExtraFiltersSchema.shape,
})

// TODO: Fix these annoying linting errors
// @ts-ignore
export type CompanySignalFilters = z.infer<
  // @ts-ignore
  typeof companySignalFiltersValidation
>

// Supress the deep nesting for validation
// @ts-ignore
export const CompanyFiltersSchema = z
  .union([
    // @ts-ignore-next-line
    companySignalFiltersValidation,
    // @ts-ignore-next-line
    z.array(companySignalFiltersValidation),
  ])
  .default([])
  .transform((filter: CompanySignalFilters | CompanySignalFilters[]) => {
    return normaliseToArray(filter)
  })

export type CompanyFilters = z.infer<typeof CompanyFiltersSchema>

// @ts-ignore
export const CompaniesSchema = z
  .object({
    list_id: z.string().optional(), // Also appears in the filters.
    searchId: z.string().optional(),
    queryId: z.string().optional(),
    sort: companySignalSortValidation.optional(),
    searches: CompanyFiltersSchema,
    nameSearch: z.string().optional(),
    pagination: Pagination,
    ids: z.array(z.string()).optional(),
  })
  .transform(async (value) => {
    if (value.queryId !== undefined) {
      const queryIdQuery = await findQueryFromQueryId(Number(value.queryId))
      if (queryIdQuery !== undefined) {
        value.searches.push(...normaliseToArray(queryIdQuery))
      }
    }

    if (value.searchId !== undefined) {
      const searchQuery = await findQueryFromSearchId(Number(value.searchId))
      if (searchQuery !== undefined) {
        value.searches.push(...searchQuery.query)
      }
    }
    return value
  })

export type CompanySearch = z.infer<typeof CompaniesSchema>

export const getDateOperator = (operator: DateOperator) => {
  return {
    after: "gt",
    before: "lt",
    equals: "equals",
  }[operator]
}

export const companyAdvancedFiltersSchema = z.object({
  General: z.object({
    General: companyCompanyFiltersSchema,
  }),
  Sector: z.object({
    Sector: companySectorsFiltersSchema,
  }),
  Financials: z.object({
    Financials: companyFundingFiltersSchema
      .merge(companyInvestorsFiltersSchema)
      .merge(companyValuationFiltersSchema),
  }),
  Team: z.object({
    Team: companyFoundersFiltersSchema
      .merge(companyEmployeesFiltersSchema)
      .merge(companyCompanySizeFiltersSchema)
      .merge(companyTalentMovementFiltersSchema),
  }),
  Website: z.object({
    Website: companyWebVisitsFiltersSchema.merge(
      companyWebsitePopularityRankFiltersSchema
    ),
  }),
  "Mobile App": z.object({
    "Mobile App": companyAppDownloadsFiltersSchema
      .merge(companyiTunesFiltersSchema)
      .merge(companyGoogleFiltersSchema),
  }),
  Product: z.object({
    Product: companyG2FiltersSchema
      .merge(companyTrustpilotFiltersSchema)
      .merge(companyActiveTechnologiesFiltersSchema),
  }),
  Socials: z.object({
    Socials: companyLinkedInFiltersSchema
      .merge(companyTwitterFiltersSchema)
      .merge(companyInstagramFiltersSchema),
  }),
  Awards: z.object({
    Awards: companyAwardsFiltersSchema,
  }),
  "My CRM": z.object({
    "My CRM": companyCRMFiltersSchema,
  }),
})
