import { prisma } from "~/utils/prisma.server"
import { JSONSafeParse } from "~/utils/JSONSafeParse"
import { normaliseToArray } from "~/utils/db/sql/utils"

export interface SignalQuery {
  query: Record<string, any>[]
  sort: Record<string, any>
}
/**
 * Resolve the query for a signal from a signalId or from the JSON
 * parsed in
 * @param searchId
 * @param query
 * @param sort
 * @param queryId
 * @return
 */
export async function resolveSignalQuery(
  searchId?: string | null,
  query?: string | null,
  sort?: string | null,
  queryId?: string | null
): Promise<SignalQuery> {
  // Preferentially use the query if supplied
  if (query) {
    return {
      query: normaliseToArray(JSONSafeParse(query)),
      sort: JSONSafeParse(sort ?? ""),
    }
  }

  if (queryId) {
    const query = await findQueryFromQueryId(Number(queryId))
    if (query !== undefined) {
      return {
        query,
        sort: JSONSafeParse(sort ?? ""),
      }
    }
  }

  // Alternatively, lookup the query from the DB with the searchId
  if (searchId) {
    const query = await findQueryFromSearchId(Number(searchId))
    if (query !== undefined) {
      return query
    }
  }

  return {
    query: normaliseToArray(JSONSafeParse(query ?? "")),
    sort: JSONSafeParse(sort ?? ""),
  }
}

/**
 * Given a query id it will return the query and sorting for it.
 * It assumes that the query from the database is safe.
 * @param queryId
 * @return - The query if found (always an array), or undefined if not found
 */
export async function findQueryFromQueryId(
  queryId: number
): Promise<Record<string, any>[] | undefined> {
  const result = await prisma.queries.findFirst({
    where: {
      id: Number(queryId),
    },
  })

  if (result) {
    return normaliseToArray(result.query)
  } else {
    console.error("No matching query found")
  }
  return undefined
}

/**
 * Given a search id it will return the query and sorting for it.
 * It assumes that the query from the database is safe.
 * @param searchId
 * @return - The search query if found { query: (always an array), sort}
 *           or undefined if not found
 */
export async function findQueryFromSearchId(
  searchId: number
): Promise<SignalQuery | undefined> {
  const result = await prisma.queriesByUser.findFirst({
    where: {
      id: searchId,
    },
    select: {
      queries: {
        select: {
          query: true,
        },
      },
      sort: true,
    },
  })

  if (result) {
    const {
      queries: { query: queryById },
      sort,
    } = result

    const sortValue: Record<string, any> = JSONSafeParse((sort as string) ?? "")
    return { query: normaliseToArray(queryById), sort: sortValue }
  } else {
    console.error("No matching record found")
  }
  return undefined
}

/**
 * Returns the query for a search ID if this user has access to use it.
 * It assumes that the query from the database is safe.
 * @param userId
 * @param searchId
 * @return - The search query if found { query: (always an array), sort}
 *           or undefined if not found
 */
export async function findUserQueryFromSearchId(
  userId: string,
  searchId: number
): Promise<SignalQuery | undefined> {
  const result = await prisma.queriesByUser.findFirst({
    where: {
      id: searchId,
    },
    select: {
      queries: {
        select: {
          query: true,
        },
      },
      sort: true,
    },
  })

  if (result) {
    const {
      queries: { query: queryById },
      sort,
    } = result

    const sortValue: Record<string, any> = JSONSafeParse((sort as string) ?? "")
    return { query: normaliseToArray(queryById), sort: sortValue }
  } else {
    console.error("No matching record found")
  }
  return undefined
}
