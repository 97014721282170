import { z } from "zod"
import { FEED_PAGE_SIZE } from "~/consts/signals"

export type SortDirection = "asc" | "desc"
export interface SortingParameter {
  column: string
  direction: SortDirection
}

/**
 * This allows the Pagination to be converted either from a number or from a
 * string. This is so that the parameters in the URL can be used to convert
 * to the Pagination without worrying about doing validation ourselves.
 */
export const Pagination = z
  .object({
    page: z
      .union([z.string(), z.number()])
      .nullish()
      .transform((s) => s ?? 0)
      .pipe(z.coerce.number()),
    limit: z
      .union([z.string(), z.number()])
      .nullish()
      .transform((s) => s ?? FEED_PAGE_SIZE)
      .pipe(z.coerce.number()),
  })
  .default({})

export type PaginationType = z.infer<typeof Pagination>
